import { Guid } from 'guid-typescript';
export class SessionUser {
    userName!: string;
    firstName!: string;
    expiryDate!: string;
    token?: string;
    userId?: Guid;
    services?: SessionServices[];
    roles?: SessionServiceRoles[];
}

export class SessionServiceRoles {
    serviceId: Guid;
    name?: string;
}

export class SessionServices {
    id: Guid;
    name?: string;
    nameEn?: string;
    code?: string;
    startDate: Date;
    endDate: Date;
    serviceKindId?: string;
    subscriptionId: Guid;
    isDelete: boolean;
    serviceTypeId?: string;
}